<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button></p>
            </div>
        </div>
        <el-tabs class="done" type="border-card">
            <el-tab-pane label="库存批次详情">
                <fixed-list :dataSource="StockBatchInfoList" :config="{isDetailDisplay: false}" @onDataSourceChange="onEditDataSourceChange" @onPageChange="onPageChangeBatch" :queryParam="queryParamBatch">
                    <p slot="elList">
                        <el-table ref="stockinfoListTable" :data="StockBatchInfoList.Result" border highlight-current-row>
                            <el-table-column v-for="(col,index,count)  in StockBatchInfoList.ColDefs.BodyFieldParams" :key="index" :prop="col.FieldName"
                            :label="col.DisplayName" :render-header="bindFilter(queryParamBatch,col,ignoreField)" :fixed="index==0&&(!config||!config.isDetailDisplay)"
                            :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0" v-if="col.Visible">
                            </el-table-column>
                            <!-- <el-table-column :label="col.LotName?col.LotName:col.DefaultLotName" v-for="(col,index) in lotManages" v-if="col.IsDisplay" :key="index" min-width="140px">
                                <template slot-scope="scope">
                                    <span>{{scope.row['LotAttr'+(index+1)]}}</span>
                                </template>
                            </el-table-column> -->
                        </el-table>
                    </p>
		        </fixed-list>
            </el-tab-pane>
            <br />
            <el-tab-pane label="库存交易详情">
                <el-table :data="StockDealInfoList.Result" border highlight-current-row>
                    <el-table-column prop="DisplayTime" label="时间">
                        <template slot-scope="scope">
                            <span v-model="scope.row.DisplayTime">{{scope.row.DisplayTime}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="DisplayChangeMemo" label="操作备注">
                        <template slot-scope="scope">
                            <span v-model="scope.row.DisplayChangeMemo">{{scope.row.DisplayChangeMemo}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="h-table-list">
                    <div class="page">
                        <el-pagination @size-change="onSizeChangeDeal"
                                       @current-change="onPageChangeDeal"
                                       :current-page="queryParamDeal.PageIndex"
                                       :page-sizes="pageSizeArrayDeal"
                                       :page-size="queryParamDeal.PageSize"
                                       layout="total, sizes, prev, pager, next, jumper"
                                       :total="StockDealInfoList.TotalCount">
                        </el-pagination>
                        <el-button type="text" class="btn-refurbish" v-on:click="onRefreshDeal">刷新</el-button>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                ignoreField: [],
                lotManages: [],
                multipleSelection: [],
                queryParamDeal: {
                    PageIndex: 1,
                    PageSize: 10,
                    Params: {
                    }
                },
                queryParamBatch: {
                    PageIndex: 1,
                    PageSize: 10,
                    Params: {
                    }
                },
                StockBatchInfoList: {
                    Result: [],
                    ColDefs:{
                        BodyFieldParams:[]
                    },
                    TotalCount: 0    
                },
                StockDealInfoList: {
                        Result: []
                    },
                dataSource: {
                    ProductCode: "",
                    WarehouseNo: "",
                    InventoryType: ""
                }
            }
        },
        props: {
            config: {
                isDetailDisplay: false
            },
            pageSizeArrayDeal: {
                type: Array,
                default: function () {
                    return [10, 20, 30, 50, 100];
                }
            },
            pageSizeArrayBatch: {
                type: Array,
                default: function () {
                    return [10, 20, 30, 50, 100];
                }
            },
        },
        mounted() {
            this.Event.$on("clearStockBatchForm", () => this.resetForm);
            //批属性管理数据
            //this.getLotManage();
            //利用事件总线，使得兄弟组件方法可以相互触发
            this.Utils.lazy(() => {
                this.Event.$on("stockData", (data) => this.onEditPageChange(data));
            });
        },
        methods: {
            onEditPageChange: function (data) {
                this.dataSource = data;
                this.loadDateDeal();
                this.loadDateBatch();
            },
            onEditDataSourceChange(ds) {
                var _this = this;
                _this.StockBatchInfoList= {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0     
                };
                _this.$nextTick(function () {
                    _this.StockBatchInfoList = ds;
                });
            },
            resetForm() {
                !this.isPassValidate && this.$refs['_editstockInfoForm'].resetFields();//清空表单
            },
            onRefreshDeal() {
                this.loadDateDeal();
            },
            onSizeChangeDeal(val) {
                this.queryParamDeal.PageSize = val;
                this.loadDateDeal();
            },
            onPageChangeDeal(val) {
                this.queryParamDeal.PageIndex = val;
                this.loadDateDeal();
            },
            loadDateDeal() {
                var _this = this;
                this.queryParamDeal.Params = {};
                this.queryParamDeal.Params.WarehouseNo = { fieldName: 'WarehouseNo', fieldValue: this.dataSource.WarehouseNo };
                this.queryParamDeal.Params.ProductCode = { fieldName: 'ProductCode', fieldValue: this.dataSource.ProductCode };
                this.queryParamDeal.Params.InventoryType = { fieldName: 'InventoryType', fieldValue: this.dataSource.InventoryType };
                this.$ajax.query("omsapi/stockinfo/stockdeallist", "post", this.queryParamDeal, data => {
                    _this.StockDealInfoList.Result = data.Result;
                });
            },
            onRefreshBatch() {
                this.loadDateBatch();
            },
            onSizeChangeBatch(val) {
                this.queryParamBatch.PageSize = val;
                this.loadDateBatch();
            },
            onPageChangeBatch(pageParam) {
                this.queryParamBatch=pageParam;
                this.loadDateBatch();
            },
            loadDateBatch() {
                var _this = this;
                this.queryParamBatch.Params.WarehouseNo = this.dataSource.WarehouseNo;
                this.queryParamBatch.Params.ProductCode = this.dataSource.ProductCode;
                this.queryParamBatch.Params.InventoryType = this.dataSource.InventoryType;
                this.$ajax.query("omsapi/stockinfo/stocknewdetaillist", "post", this.queryParamBatch, data => {
                    _this.StockBatchInfoList = data;
                });
            },
            //批属性管理数据
            getLotManage() {
                this.$ajax.send("omsapi/lotmanage/search", "get", { excludeOldField: true }, (data) => {
                    for (let index = 0; index < data.Result.length; index++) {
                        // if (index > 1) {//排除批属性1、2
                        var lot = data.Result[index];
                        this.lotManages.push(lot);
                        // }
                    }
                });
            },
        }
    }
</script>

